interface ISlidelySdkConfig {
  onLogin?: () => void;
  onLogout?: () => void;
  appId: string;
}

interface ISlidelyUser {
  firstName: string;
  lastName: string;
  email: string;
  profilePictureUrl: string;
  hasPromoAccount: boolean;
  userToken?: string;
  timestamp?: number;
  access_token?: boolean;
}

interface ISlidelyAuthData {
  access_token?: string;
  expires_in?: number;
  state?: string;
  token_type?: string;
}

interface ISlidelyMessage extends ISlidelyAuthData {
  data: ISlidelyUser;
  event: string;
  type: string;
  trigger: boolean;
}

class SDK {
  private static setCookie(data: ISlidelyAuthData) {
    const date = new Date();
    date.setSeconds(date.getSeconds() + (data.expires_in || 0));
    document.cookie = `selc=${data.access_token};expires=${date.toUTCString()};path=/`;
  }

  private static removeCookie() {
    document.cookie = 'selc=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
  }

  private cookieName = 'ezSession';

  private domain = `${window.location.protocol}//${window.location.host}`;

  private onLogin?: () => void;

  private onLogout?: () => void;

  private appId = '';

  private logoutBlockers: () => void[];

  private loginBlockers: () => void[];

  private authData: ISlidelyAuthData = {};

  private user: ISlidelyUser | null = null;

  private logged = false;

  private readyCallback?: () => void;

  private loginCallback?: () => void;

  private cancelCallback?: () => void;

  private popup?: Element;

  init({ onLogin, onLogout, appId }: ISlidelySdkConfig) {
    this.onLogin = onLogin;
    this.onLogout = onLogout;
    this.appId = appId;

    this.bindEvents();
    this.getLocalData();

    if (!this.user) {
      this.getCurrentSession();
    } else {
      this.logged = true;
      if (this.readyCallback) this.readyCallback();
      if (this.onLogin) this.onLogin();
    }
  }

  bindEvents() {
    window.addEventListener('message', (event) => {
      const re = new RegExp(`^https?://${window.location.host}`);

      if (!re.test(event.origin)) return;

      let message: null | ISlidelyMessage = null;

      try {
        message = JSON.parse(event.data);
      } catch (e) {
        console.log(e);
      }

      if (!message || message.trigger || message.type === 'helloWebsite') return;

      switch (message.event) {
        case 'userInfoUpdate':
          const { firstName, lastName, email, profilePictureUrl, hasPromoAccount } = message.data;

          this.user = {
            ...(this.user || {}),
            firstName,
            lastName,
            email,
            profilePictureUrl,
            hasPromoAccount,
          };
          this.setLocalData();
          if (this.onLogin) this.onLogin();
          break;
        case 'deleteUser':
          this.logout(() => {
            window.location.href = '/';
          });
          break;
        case 'forceChangePwd':
          window.location.href = '/change-password-force';
          break;
        default:
          const { access_token, expires_in, state, token_type } = message;

          this.authData = {
            ...this.authData,
            access_token,
            expires_in,
            state,
            token_type,
          };

          if (this.popup) document.body.removeChild(this.popup);
          SDK.setCookie(this.authData);
          this.logged = true;
          this.signUpToPromo(this.onLogin);
      }
    });
  }

  login(callback?: () => void, cancelCallback?: () => void) {
    for (let i = 0; i < this.loginBlockers.length; ++i) {
      if (this.loginBlockers[i]()) return;
    }

    this.loginCallback = callback;
    this.cancelCallback = cancelCallback;

    this.openLoginPopup('login', {});
  }

  register(callback: () => void, cancelCallback: () => void, params: any) {
    for (let i = 0; i < this.loginBlockers.length; ++i) {
      if (this.loginBlockers[i]()) return;
    }

    this.loginCallback = callback;
    this.cancelCallback = cancelCallback;

    this.openLoginPopup('register', params);
  }

  static generateState() {
    const src = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let state = '';

    for (let i = 0; i < 32; i++) {
      state += src[(Math.random() * src.length) | 0]; // eslint-disable-line
    }
    return state;
  }

  getLocalData() {
    if (!this.isSessionCookieExists()) return false;

    const data = JSON.parse(localStorage.getItem('slideSdkData') || 'false');

    if (!data) return false;

    this.user = data.timestamp < Date.now() - 24 * 60 * 60 * 1000 ? {} : data;

    return true;
  }

  setLocalData() {
    localStorage.setItem('slideSdkData', JSON.stringify(this.user));
  }

  async getCurrentSession(callback?: () => void) {
    const data = await (await fetch('/data/user/session')).json();

    if (data.success && data.connected) {
      this.user = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        profilePictureUrl: data.profilePictureUrl,
        userToken: data.token,
        timestamp: Date.now(),
        hasPromoAccount: data.hasPromoAccount,
      };

      this.setLocalData();

      this.logged = true;
      if (this.onLogin) this.onLogin();
      if (callback) callback();
      if (this.loginCallback) this.loginCallback();
    }

    if (this.readyCallback) this.readyCallback();
  }

  static getJsonpRandomName() {
    return `slideSdkJsonpFn${Date.now()}`;
  }

  isSessionCookieExists() {
    return !!SDK.getCookie(this.cookieName);
  }

  static getCookie(cookieName: string) {
    const name = `${cookieName}=`;
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1);
      if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
    }

    return '';
  }

  static sendJsonp(params: { url: string; callback: (data: any) => void; autoRemove?: boolean }) {
    if (!params.url) return;

    const fnName = SDK.getJsonpRandomName();
    const script = document.createElement('script');
    const weld = params.url.indexOf('?') === -1 ? '?' : '&';

    window[fnName] = params.callback;
    script.src = `params.url${weld}callback=${fnName}`;
    document.head.append(script);

    if (params.autoRemove) {
      script.addEventListener('load', () => document.head.removeChild(script));
    }
  }

  getSlidelySession(callback: () => void) {
    SDK.sendJsonp({
      url: `${this.domain}/data/user/session`,
      callback: (data) => {
        if (!data.connected) {
          this.logout(() => this.login(callback));
          return;
        }

        if (this.user) {
          const { firstName, userToken, email, profilePictureUrl } = this.user;

          if (
            data.firstName !== firstName ||
            data.token !== userToken ||
            data.email !== email ||
            data.profilePictureUrl !== profilePictureUrl
          ) {
            this.logout(() => this.login(callback));
          } else if (callback) {
            callback();
          }
        } else {
          this.user = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            profilePictureUrl: data.profilePictureUrl,
            userToken: data.token,
            timestamp: Date.now(),
            hasPromoAccount: data.hasPromoAccount,
          };

          this.setLocalData();

          this.logged = true;
          if (this.onLogin) this.onLogin();
          if (callback) callback();
        }
      },
    });
  }

  async signUpToPromo(callback?: () => void) {
    const data = await (await fetch('/promoVideos/user/sign-up', { method: 'POST' })).json();

    if (data.response && data.response.success) {
      this.getCurrentSession(callback);
    }
  }

  openLoginPopup(target: string, params: { [key: string]: string }) {
    this.popup = document.createElement('div');
    this.popup.setAttribute('class', `login-dialog-wrapper ${target}`);
    let encodedParams = '';
    Object.entries(params).forEach(([paramName, paramValue]) => {
      encodedParams += `&${encodeURIComponent(paramName)}=${encodeURIComponent(paramValue)}`;
    });
    const { domain, appId } = this;
    const iframeUrl = `${domain}/${target}?client_id=${appId}&display=popup&state=${SDK.generateState()}${encodedParams}`;
    this.popup.innerHTML = `<div class="login-dialog">
            <iframe 
                data-qaid="login_iframe" 
                allowtransparency="true" 
                style="width: 100%; height: 100%" 
                src="${iframeUrl}"
            >
            </iframe>
            <div data-qaid="login_dialog_close_button" class="login-close-button hidden">
            </div>
        </div>`;
    document.body.appendChild(this.popup);
    document.querySelector('.login-close-button')?.addEventListener('click', () => {
      if (this.popup) document.body.removeChild(this.popup);
      if (this.cancelCallback) this.cancelCallback();
    });
    setTimeout(() => {
      const closeBtn = document.querySelector('.login-close-button');

      if (closeBtn) {
        closeBtn.className = 'login-close-button';
      }
    }, 2000);
  }

  async logout(callback: (success?: boolean) => void) {
    for (let i = 0; i < this.logoutBlockers.length; ++i) {
      if (this.logoutBlockers[i]()) return;
    }

    try {
      await fetch('/promoVideos/user/logout');

      SDK.removeCookie();
      this.user = null;
      this.authData = {};

      this.logged = false;
      localStorage.removeItem('slideSdkData');

      if (callback) callback(true);
      if (this.onLogout) this.onLogout();
    } catch (e) {
      if (callback) callback();
    }
  }

  getLoggedIn() {
    return this.logged;
  }

  getAuthToken() {
    return this.user?.access_token || '';
  }

  getResponseData() {
    return this.user;
  }

  ready(callback: () => void) {
    if (this.user) {
      callback();
    } else {
      this.readyCallback = callback;
    }
  }
}

// eslint-disable-next-line
// @ts-ignore
window.resolveSlidelySdk(new SDK());
